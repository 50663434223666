import { hover } from '@testing-library/user-event/dist/hover';
import React from 'react';

export function Button({ 
  children, 
  onClick, 
  className = '', 
  variant = 'default' 
}) {
  const baseClasses = 'px-4 py-2 rounded';
  const variantClasses = {
    default: 'bg-amber-400',
    destructive: 'bg-rose-900 text-white',
    //  hover: 'hover:bg-amber-500'
  };

  return (
    <button 
      onClick={onClick} 
      className={`${baseClasses} ${variantClasses[variant]} ${className}`}
    >
      {children}
    </button>
  );
}