import { useState } from 'react';
import { Button } from "./components/ui/button";
import { 
  Card, 
  CardContent, 
  CardDescription, 
  CardFooter, 
  CardHeader, 
  CardTitle 
} from "./components/ui/card";
import { Input } from "./components/ui/input";
import { TabNavigation, AuthenticatedHeader } from "./components/ui/additional";

// Load variables (Better to use env secrets later)
// const SAMUGEN_ID=process.env.REACT_APP_SAMUGEN_ID
const REPORTGEN_ID=process.env.REACT_APP_REPORTGEN_ID
const LAYTRANS_ID=process.env.REACT_APP_LAYTRANS_ID
const INTERNAL_USERNAME = process.env.REACT_APP_INTERNAL_USERNAME;
const INTERNAL_PASSWORD = process.env.REACT_APP_INTERNAL_PASSWORD;
const EXTERNAL_USERNAME = process.env.REACT_APP_EXTERNAL_USERNAME;
const EXTERNAL_PASSWORD = process.env.REACT_APP_EXTERNAL_PASSWORD;


// Add this function outside your component
function getRelativeTime(date: Date): string {
  const now = new Date();
  const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);
  
  const intervals = [
    { label: 'year', seconds: 31536000 },
    { label: 'month', seconds: 2592000 },
    { label: 'week', seconds: 604800 },
    { label: 'day', seconds: 86400 },
    { label: 'hour', seconds: 3600 },
    { label: 'minute', seconds: 60 },
    { label: 'second', seconds: 1 }
  ];

  for (const interval of intervals) {
    const count = Math.floor(diffInSeconds / interval.seconds);
    if (count >= 1) {
      return count === 1 
        ? `${count} ${interval.label} ago` 
        : `${count} ${interval.label}s ago`;
    }
  }

  return 'just now';
}



export default function Home() {
  // State for authentication
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [authError, setAuthError] = useState('');
  const [userType, setUserType] = useState<'internal' | 'external' | null> (null);

  // State for active tab
  const [activeTab, setActiveTab] = useState('samugen');


  // Authentication handler
  const handleLogin = () => {
    if (username === INTERNAL_USERNAME && password === INTERNAL_PASSWORD) {
      setIsAuthenticated(true);
      setUserType('internal');
      setAuthError('');
    } else if (username === EXTERNAL_USERNAME && password === EXTERNAL_PASSWORD) {
      setIsAuthenticated(true);
      setUserType('external');
      setAuthError('');
    } else {
      setAuthError('Invalid username or password');
      setIsAuthenticated(false);
      setUserType(null);
    }
  };
  
  // Tab change handler
  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
  };

  // Download handler with authentication check
  const handleDownload = async (fileId, app_name: string="application.exe") => {
    if (!isAuthenticated) {
      setAuthError('Please log in to download');
      return;
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_SERVER}/download/${fileId}`);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = app_name; // Set desired filename
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Download failed:', error);
    }
  };
  

  // Logout handler
  const handleLogout = () => {
    setIsAuthenticated(false);
    setUsername('');
    setPassword('');
    setUserType(null);
  };


  // If not authenticated, show login form
  if (!isAuthenticated) {
    return (
      <div className="max-w-md mx-auto mt-20 p-6 bg-white shadow-md rounded-lg">

        <div className="logo-container">
          <div className="logo">
            <img src="ali_logo.png" alt="ALI Parse Logo" className="logo-image" />
          </div>
          <div className="text-container">
            <h1 className="product-name">
              <span className="ali">ALI</span><span className="gen">Gen</span>
            </h1>
            <p className="product-tagline">On-Device AI Applications <span className="version">0.0.1</span></p>
          </div>
        </div>        


        <h2 className="text-2xl font-bold mb-6 text-center">Login</h2>
        <div className="space-y-4">
          <Input 
            type="text" 
            placeholder="Username" 
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <Input 
            type="password" 
            placeholder="Password" 
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {authError && <p className="text-red-500 text-sm">{authError}</p>}
          <Button 
            onClick={handleLogin} 
            className="w-full"
          >
            Login
          </Button>
        </div>
      </div>
    );
  }

  // Authenticated view
  return (
    <div className="max-w-7xl mx-auto p-4 md:p-6 lg:p-8">
      <AuthenticatedHeader onLogout={handleLogout} />
      
      <TabNavigation 
        activeTab={activeTab} 
        onTabChange={handleTabChange} 
      />
      {activeTab === 'samugen' && userType === 'internal' && (
        <Card>
          <CardHeader>
            <CardTitle>SamuGen</CardTitle>
            <CardDescription>AI in your pocket</CardDescription>
          </CardHeader>
          <CardContent>
            <p>SamuGen is an on-device Generative AI-based document creation tool that processes all data locally, ensuring maximum data security and efficiency.</p>
            <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
              <li>Document Fusion</li>
              <li>Q&A functionalities (AskME)</li>
              <li>Document content generation (WriteME)</li>
            </ul>

            {/* New version download section */}
            <div className="mt-4">
              <h3 className="font-bold mb-2">Available Versions</h3>
              <div className="space-y-2">

                {/* Version 0.2.4 */}
                <div className="border rounded-md p-3">
                  <div className="flex justify-between items-center">
                    <span className="font-semibold">
                      SamuGen v0.2.4-alpha-internal
                      <span className="text-xs text-gray-500 ml-2">
                        {getRelativeTime(new Date('2024-12-12T18:45:00'))}
                      </span>
                    </span>
                    <Button
                      onClick={() => handleDownload(process.env.REACT_APP_SAMUGEN_IDv024, "samugen_v0.2.4-alpha-internal.exe")}
                    >
                      Download
                    </Button>
                  </div>
                  <details className="mt-2">
                    <summary className="cursor-pointer text-sm text-gray-600">
                      Release Notes
                    </summary>
                    <ul className="list-disc pl-5 mt-2 text-sm">
                    <li>Support <strong>Local-mini (1.7b)</strong> and <strong>Local-plus (8b)</strong> to selectively run sLLMs</li>
                    <li>Fix bug for running Samugen, epecially ipexLLM with older Windows Intel PCs</li>
                    </ul>
                  </details>
                </div>


                {/* Version 1.0.0 */}
                <div className="border rounded-md p-3">
                  <div className="flex justify-between items-center">
                    <span className="font-semibold">
                      SamuGen v0.2.3-alpha-internal
                      <span className="text-xs text-gray-500 ml-2">
                        {getRelativeTime(new Date('2024-12-11T12:30:00'))}
                      </span>
                    </span>
                    <Button
                      onClick={() => handleDownload(process.env.REACT_APP_SAMUGEN_IDv023, "samugen_v0.2.3-alpha-internal.exe")}
                    >
                      Download
                    </Button>
                  </div>
                  <details className="mt-2">
                    <summary className="cursor-pointer text-sm text-gray-600">
                      Release Notes
                    </summary>
                    <ul className="list-disc pl-5 mt-2 text-sm">
                    <li>Support <strong>smollm2-1.7b</strong> with updated <strong>IPEXLLM-v2.2.0b20241130.post0</strong> to run on 16GB consumer-grade PCs</li>
                    <li>Layout Detector Parsing is now reduced to less than 1s from 3s</li>
                    <li>Include OCR reader and bug fix for increasing memory usage issue with large scanned PDF files</li>
                    <li>OCR inference is reduced to 2s [Detector in NPU/GPU/CPU and Recognition in CPU looks the best combination.] Input image size is fixed to (800, 1130) for NPU offload for detector</li>
                    <li>Dynamic Port Allocation for all backend applications: NextJSAPP, Ollama, ALIParseRAG, LayoutDet</li>
                    <li>Supports:
                      <pre>
                        docx, doc, csv, epub, hwp, hwpx, json, md, msg, odt, ppt, pptx, rst, rtf, tsv, txt, xls, xlsx, xml, html
                      </pre>
                    </li>
                    <li>Auto detect hallucination in generation (not in Q&A chat) and retry 3 times; if it still fails, then ask the user to retry</li>
                    <li>Auto run Samugen as administrator</li>
                    <li>Fix Bug for detecting Discrete GPUs like NVIDIA and give it higher priority than Intel GPUs</li>
                    <li>Support <strong>ALIParse-v0.4.2-20241210</strong></li>
                    </ul>
                  </details>
                </div>

                {/* Version 1.1.0 */}
                <div className="border rounded-md p-3">
                  <div className="flex justify-between items-center">
                  <span className="font-semibold">
                      SamuGen v0.2.2-alpha-internal
                      <span className="text-xs text-gray-500 ml-2">
                        {getRelativeTime(new Date('2024-11-29T18:30:00'))}
                      </span>
                    </span>                    
                    <Button
                      onClick={() => handleDownload(process.env.REACT_APP_SAMUGEN_IDv022, "samugen_v0.2.2-alpha-internal.exe")}
                    >
                      Download
                    </Button>
                  </div>
                  <details className="mt-2">
                    <summary className="cursor-pointer text-sm text-gray-600">
                      Release Notes
                    </summary>
                    <ul className="list-disc pl-5 mt-2 text-sm">
                      <li>Runs two separate backend APIs to handle file parsing and query searching simultaneously</li>
                      <li>Substitutes Qdrant with folder-based VectorDB. 
                        <em>[Not recommended for scaling use and complex operations like filtering cannot be performed, but is blazingly fast for upload and search operations and can allow multiple connections unlike Qdrant-local.]</em> 
                        <strong> Will later figure out a permanent fix to this VectorDB issue.</strong>
                      </li>
                      <li>Normalized CSS to consider multiple default settings of <code>dark</code> and <code>light</code> modes in different laptops</li>
                      <li>Prioritize external NVIDIA GPU if available, and it automatically selects Intel, Nvidia, or AMD GPUs to run LLMs</li>
                      <li>Two test-files are added and they will automatically appear when clicked on the <code>Parse Docs</code> button. You need to parse them first even though they are in the <code>parsed</code> folder</li>
                      <li>Temporarily disable OCR reader as it is very slow (6s+2s at least). 
                        <em>If you upload very large scanned files, it will freeze the Samugen-app window. For now, everything should run within 10GB of memory as no OCR model is loaded.</em>
                      </li>
                    </ul>
                  </details>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      )}

      {activeTab === 'samugen' && userType === 'external' && (
        <Card>
          <CardHeader>
            <CardTitle>SamuGen</CardTitle>
            <CardDescription>AI in your pocket</CardDescription>
          </CardHeader>
          <CardContent>
            <p>SamuGen is an on-device Generative AI-based document creation tool that processes all data locally, ensuring maximum data security and efficiency.</p>
            <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
              <li>Document Fusion</li>
              <li>Q&A functionalities (AskME)</li>
              <li>Document content generation (WriteME)</li>
            </ul>

            {/* New version download section */}
            <div className="mt-4">
              <h3 className="font-bold mb-2">Available Versions</h3>
              <div className="space-y-2">
                <div className="border rounded-md p-3">
                  <div className="flex justify-between items-center">
                    <span className="font-semibold">
                      SamuGen v0.2.4-alpha-internal
                      <span className="text-xs text-gray-500 ml-2">
                        {getRelativeTime(new Date('2024-12-12T18:45:00'))}
                      </span>
                    </span>
                    <Button
                      onClick={() => handleDownload(process.env.REACT_APP_SAMUGEN_IDv024, "samugen_v0.2.4-alpha-internal.exe")}
                    >
                      Download
                    </Button>
                  </div>
                  <details className="mt-2">
                    <summary className="cursor-pointer text-sm text-gray-600">
                      Release Notes
                    </summary>
                    <ul className="list-disc pl-5 mt-2 text-sm">
                      <li>Disabled for external sharing</li>
                    </ul>
                  </details>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      )}      

      {activeTab === 'reportgen' && (
        <Card>
          <CardHeader>
            <CardTitle>ReportGen</CardTitle>
            <CardDescription>AI knows where it is</CardDescription>
          </CardHeader>
          <CardContent>
            <p>ReportGen is a customizable AI-powered report generation solution designed to streamline and automate the creation of reports.</p>
            <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
              <li>AutoD2W (Automatic change of Dark Unstructured data to White Structurized knowledge)</li>
              <li>AI-powered report writing</li>
            </ul>
          </CardContent>
          <CardFooter>
          <Button
              onClick={() => {
                alert("ReportGen is not available for you to download.\nPlease contact ALIGen Team.")
                // if (REPORTGEN_ID?.length === 0){
                //   alert("ReportGen is not available for you to download.\nPlease contact ALIGen Team.")
                // } else {
                //   handleDownload(REPORTGEN_ID, 'reportgen.exe')
                // }
              }}
            >
              Download
            </Button>
          </CardFooter>
        </Card>
      )}
      {activeTab === 'laytrans' && (
        <Card>
          <CardHeader>
            <CardTitle>LayTrans</CardTitle>
            <CardDescription>AI translates as it is</CardDescription>
          </CardHeader>
          <CardContent>
            <p>LayTranslator is an AI-powered document translation tool that uniquely preserves the original layout and formatting of documents while translating content into various languages.</p>
            <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
              <li>Support for multiple file types (DOCX, PDF, CSV, PPT, etc.)</li>
              <li>Computer vision for layout retention</li>
              <li>Natural language processing (NLP) for accurate translations</li>
            </ul>
          </CardContent>
          <CardFooter>
          <Button
              onClick={() => {
                alert("LayTrans is not available for you to download.\nPlease contact ALIGen Team.")
                // if (LAYTRANS_ID?.length === 0){
                //   alert("LayTrans is not available for you to download.\nPlease contact ALIGen Team.")
                // } else {
                //   handleDownload(LAYTRANS_ID, 'laytrans.exe')
                // }                
              }}
            >
              Download
            </Button>
          </CardFooter>
        </Card>
      )}
    </div>
  );
}