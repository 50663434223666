import { Button } from "./button";

const TabNavigation = ({ activeTab, onTabChange }) => {
  const tabs = [
    { id: 'samugen', label: 'SamuGen' },
    { id: 'reportgen', label: 'ReportGen' },
    { id: 'laytrans', label: 'LayTrans' }
  ];


  return (
    <div className="flex justify-center gap-4 mb-12">
      {tabs.map((tab) => (
        <Button
          key={tab.id}
          variant={activeTab === tab.id ? "default" : "secondary"}
          onClick={() => onTabChange(tab.id)}
          className={`
            text-lg font-bold px-6 py-3 min-w-[140px]
            transition-all duration-200
            hover:scale-105 hover:shadow-lg
            ${activeTab === tab.id 
              ? 'border-2 border-amber-400'
              : 'hover:border-2 hover:border-gray-300'
            }
          `}
        >
          {tab.label}
        </Button>
      ))}
    </div>
  );
};



const AuthenticatedHeader = ({ onLogout }) => {
  return (
    <div className="mb-8">
      <div className="flex justify-between items-start mb-6">
        {/* Left side - Logo and Title */}
        <div className="flex-1">
          <div className="logo-container -ml-5">
            <div className="logo pl-0">
              <img src="ali_logo.png" alt="ALI Parse Logo" className="logo-image pl-0" />
            </div>
            <div className="text-container">
              <h1 className="product-name">
                <span className="ali">ALI</span><span className="gen">Gen</span>
              </h1>
              <p className="product-tagline">On-Device AI Applications <span className="version">0.0.1</span></p>
            </div>
          </div>
        </div>
        
        {/* Right side - Logout Button */}
        <Button 
          variant="destructive" 
          onClick={onLogout}
          className="mt-4"
        >
          Logout
        </Button>
      </div>

      {/* Header Text - Centered below the logo and logout */}
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold">Smart Generative AI Assistants</h1>
        <p className="text">Download our cutting-edge ondevice, offline AI applications</p>
      </div>
    </div>
  );
};



export  {TabNavigation, AuthenticatedHeader};